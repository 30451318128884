<template>
  <div class="orderStatus list-state-group">
    <el-radio-group v-model="query.paymentStatus" size="mini" @change="statusChange">
      <el-radio-button v-for="{ label, value } in statusList" :label="value" :key="label">{{ label }}</el-radio-button>
    </el-radio-group>
  </div>
</template>

<script>
import { deepClone } from '@/components/avue/utils/util'
import { checkPermission } from '@/utils'
let statusList = [
  { label: '未支付', value: 0, permission: checkPermission('externaladmin:financial:orderReview:unPay') },
  { label: '已支付', value: 1, permission: checkPermission('externaladmin:financial:orderReview:isPay') }
]
statusList = statusList.filter((item) => item.permission)
export default {
  components: {},
  props: {
    query: {
      type: Object,
      required: true
    },
    init: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      statusList
    }
  },
  computed: {},
  methods: {
    statusChange() {
      if (this.query.paymentStatus === 0) {
        this.query.orderItems = [{ asc: false, column: 'create_time' }]
      } else {
        this.query.orderItems = [{ asc: false, column: 'payment_time' }]
      }
      const newQuery = deepClone(this.query)
      for (let key in newQuery) {
        if (key !== 'paymentStatus' && key !== 'orderItems') {
          this.$delete(this.query, key)
        }
      }

      this.init()
    }
  }
}
</script>

<style scoped lang="scss"></style>
